import React, { useCallback, useState } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import { Box, Link, Text } from '@qga/roo-ui/components';
import { RECAPTCHA_JS, RECAPTCHA_SITE_KEY } from 'config';
import { loadScript } from 'lib/script';
import { captureErrorInSentry } from 'lib/errors';

const MAX_SCRIPT_LOAD_RETRIES = 10;

const useRecaptcha = () => {
  const [hasInitialized, setInitialized] = useState(false);
  const [hasLoadingError, setLoadingError] = useState(false);

  const getToken = useCallback(async () => {
    const grecaptcha = window?.grecaptcha;
    if (!grecaptcha) return;

    let token;
    try {
      token = await grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'qh_contact_us_form' });
    } catch (error) {
      captureErrorInSentry(error);
    }

    return token;
  }, []);

  const initialize = useCallback(() => {
    const grecaptcha = window?.grecaptcha;
    if (!grecaptcha) return;

    grecaptcha.ready(() => setInitialized(true));
  }, []);

  const loadRecaptcha = useCallback(async () => {
    try {
      await loadScript({
        url: RECAPTCHA_JS,
        maxRetries: MAX_SCRIPT_LOAD_RETRIES,
      });

      initialize();
    } catch (error) {
      setLoadingError(true);
    }
  }, [initialize]);

  const RecaptchaBranding = ({ ...rest }) => (
    <Box data-testid="recaptcha-branding">
      <Text {...rest}>This site is protected by reCAPTCHA and the Google{` `}</Text>
      <Link as={Text} {...rest} href="https://policies.google.com/privacy" target="_blank" data-testid="privacy-policy">
        Privacy Policy
      </Link>
      <Text {...rest}>{` and `}</Text>
      <Link as={Text} {...rest} href="https://policies.google.com/terms" target="_blank" data-testid="terms-of-service">
        Terms of Service
      </Link>
      <Text {...rest}>{` `}apply.</Text>
    </Box>
  );

  useIsomorphicLayoutEffect(() => {
    if (!hasInitialized) loadRecaptcha();
  }, [hasInitialized, loadRecaptcha]);

  return { getToken, hasInitialized, hasLoadingError, RecaptchaBranding };
};

export default useRecaptcha;
