import React, { useState, useMemo, Fragment, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Flex, Text, Heading } from '@qga/roo-ui/components';
import AppLink from 'components/AppLink';
import LocationAutocompleter from 'components/LocationAutocompleter';
import AvailabilityDatePicker from 'components/AvailabilityDatePicker';
import OccupantPicker from 'components/OccupantPicker';
import PayWithButtonGroup from 'components/PayWithButtonGroup';
import PayWithToggleMessage from 'components/PayWithToggleMessage';
import { getPayWithToggleMessage } from 'store/campaign/campaignSelectors';
import stringifyQueryValues from 'lib/search/stringifyQueryValues';
import { useDataLayer } from 'hooks/useDataLayer';
import omit from 'lodash/omit';
import capitalize from 'lodash/capitalize';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { InputErrorField, PayWithWrapper, SubmitButton, SubmitButtonBox } from './HotelsTab.style';
import isEmpty from 'lodash/isEmpty';
import { registerGclid } from '../../sessionStorage';
import { getQueryParams } from 'store/router/routerSelectors';
import { updateQuery } from 'store/search/searchActions';
import { addDays } from 'lib/date';
import { updateSearchLocalStorage } from './../../localStorage';

type SearchQueryProps = {
  location?: string;
  checkIn?: string;
  checkOut?: string;
  adults?: number;
  children?: number;
  infants?: number;
  payWith?: string;
  id?: number;
  isPropertySearch?: boolean;
};

type SearchLocationError = {
  message?: string;
};

type SearchDatesError = {
  message?: string;
};

const labelOptions = { color: 'greys.alto' };

const mapQueryToGAPayload = (query) =>
  Object.keys(query)
    .map((inputType) => {
      switch (inputType) {
        case 'location':
          if (query.isPropertySearch) {
            return { type: 'Property Search', value: `${query.propertyName} Selected` };
          } else {
            return { type: 'Location Search', value: `${query.location} Selected` };
          }
        case 'checkIn':
          return { type: 'Date Calendar', value: 'Checkin Date Selected' };
        case 'checkOut':
          return { type: 'Date Calendar', value: 'Checkout Date Selected' };
        case 'adults':
          return { type: 'Guests Dropdown', value: `Guests Adults ${query.adults} Selected` };
        case 'children':
          return { type: 'Guests Dropdown', value: `Guests Children ${query.children} Selected` };
        case 'infants':
          return { type: 'Guests Dropdown', value: `Guests Infants ${query.infants} Selected` };
        case 'payWith':
          return { type: 'Points and Cash Toggle', value: `${capitalize(query.payWith)} Selected` };
        default:
          return null;
      }
    })
    .filter(Boolean);

const HotelsTab = () => {
  const router = useRouter();
  const gclid = router.query.gclid ?? null;

  useEffect(() => {
    if (gclid) {
      registerGclid(gclid);
    }
  }, [gclid]);

  const dispatch = useDispatch();
  const { emitInteractionEvent } = useDataLayer();
  const today = new Date();
  const [searchLocationError, setSearchLocationError] = useState<SearchLocationError>({});
  const [searchDatesError, setSearchDatesError] = useState<SearchDatesError>({});
  const { isLessThanBreakpoint } = useBreakpoints();
  const isMobileOrTablet = isLessThanBreakpoint(1);
  const payWithToggleMessage = useSelector(getPayWithToggleMessage);
  const query = useSelector(getQueryParams);
  const [searchQuery, setSearchQuery] = useState<SearchQueryProps>({
    location: query.location ? query.location : null,
    checkIn: query.checkIn ? query.checkIn : today,
    checkOut: query.checkOut ? query.checkOut : addDays(today, 1),
    adults: query.adults ? Number(query.adults) : 2,
    children: query.children ? Number(query.children) : 0,
    infants: query.infants ? Number(query.infants) : 0,
    payWith: query.payWith ? query.payWith : 'cash',
    id: query.id ? query.id : null,
    isPropertySearch: query.isPropertySearch ? query.isPropertySearch : null,
  });

  const { location, checkIn, checkOut, adults, children, infants, payWith } = searchQuery;
  const checkInDate = checkIn ? new Date(checkIn) : undefined;
  const checkOutDate = checkOut ? new Date(checkOut) : undefined;

  const [selectedDates, setSelectedDates] = useState({ startDate: checkInDate, endDate: checkOutDate });
  const selectedOccupants = useMemo(() => ({ adults, children, infants }), [adults, children, infants]);
  const searchUrl = useMemo(() => {
    const { id: propertyId, isPropertySearch, ...rest } = searchQuery;
    const omitParams = isPropertySearch ? ['location', 'propertyName', 'excludeParams'] : ['propertyName', 'excludeParams'];
    const searchQueryString = stringifyQueryValues(omit(rest, omitParams));
    const searchPath = isPropertySearch && propertyId ? `/properties/${propertyId}` : '/search/list';

    if (!searchQuery?.location || !searchQuery?.checkIn || !searchQuery?.checkOut) {
      return undefined;
    } else {
      return gclid ? `${searchPath}?${searchQueryString}&gclid=${gclid}` : `${searchPath}?${searchQueryString}`;
    }
  }, [gclid, searchQuery]);

  const clearErrors = (payload) => {
    if (searchLocationError && payload.location) {
      setSearchLocationError({});
    } else if (searchDatesError && payload.checkIn && payload.checkOut) {
      setSearchDatesError({});
    }
  };

  const onUpdateQuery = (payload) => {
    clearErrors(payload);

    setSearchQuery({ ...searchQuery, ...payload });

    const queryEvents = mapQueryToGAPayload(payload);
    queryEvents.forEach((gaPayload) => {
      emitInteractionEvent(gaPayload);
    });
  };

  const onSubmitQuery = () => {
    if (!searchQuery?.location || !searchQuery?.checkIn || !searchQuery?.checkOut) {
      if (!searchQuery?.location) {
        setSearchLocationError({ message: 'Enter a destination or hotel name' });
      }
      if (!searchQuery?.checkIn || !searchQuery?.checkOut) {
        setSearchDatesError({ message: 'Please select dates' });
      }
      if (searchQuery.checkOut === null) {
        setSearchDatesError({ message: 'Select check-out date' });
      }
    } else {
      const payload = {
        location: searchQuery.location,
        checkIn: searchQuery.checkIn,
        checkOut: searchQuery.checkOut,
        adults: searchQuery.adults,
        children: searchQuery.children,
        infants: searchQuery.infants,
        payWith: searchQuery.payWith,
        id: searchQuery.id,
        isPropertySearch: searchQuery.isPropertySearch,
      };
      updateSearchLocalStorage(payload);

      dispatch(updateQuery(payload));
      const type = `${searchQuery.isPropertySearch ? 'Property' : 'Location'} Search`;
      emitInteractionEvent({ type, value: `${location}` });
    }
  };

  const onRouteToLocation = (payload) => {
    clearErrors(payload);

    onUpdateQuery({ ...payload, isPropertySearch: false });
  };

  const onRouteToProperty = (payload) => {
    clearErrors(payload);

    onUpdateQuery({ ...payload, location: payload.propertyName, isPropertySearch: true });
  };

  const onClickOutside = useCallback(() => {
    setSelectedDates({ startDate: undefined, endDate: undefined });
  }, [setSelectedDates]);

  return (
    <Fragment>
      {/* This Heading was added for SEO optimisation purpose and it should be used only in the Homepage */}
      <Heading.h1 fontSize="md" pb={4} mb={0}>
        Book Hotels & Accommodation
      </Heading.h1>
      <Flex alignItems="center" flexWrap="wrap">
        <Box pr={[0, 0, 4]} width={['100%', '100%', '35%']} order={1}>
          <LocationAutocompleter
            locationName={location}
            labelOptions={labelOptions}
            updateQuery={onRouteToLocation}
            routeToProperty={onRouteToProperty}
            placeholder="Enter a destination or hotel name"
            error={!!searchLocationError?.message}
          />
        </Box>
        {(!isEmpty(searchLocationError) || !isEmpty(searchDatesError)) && (
          <>
            <Box width={['100%', '100%', '35%']} pr={[0, 0, 4]} order={[2, 2, 4]}>
              {searchLocationError?.message && <InputErrorField error={searchLocationError} data-testid="missing-location-error" />}
            </Box>
            <Box width={['100%', '100%', '40%']} pr={[0, 0, 4]} order={[4, 3, 5]}>
              {searchDatesError?.message && <InputErrorField error={searchDatesError} data-testid="dates-missing-error" />}
            </Box>
          </>
        )}
        <Box pr={[0, 0, 4]} pt={[4, 4, 0]} width={['100%', '100%', '40%']} order={[3, 2]} data-testid="stay-date-picker">
          <AvailabilityDatePicker
            selectedDates={selectedDates}
            labelOptions={labelOptions}
            backgroundColor={'white'}
            clearSelectedDates={onClickOutside}
            updateQuery={onUpdateQuery}
            isHomepage
            anchorX="right"
            isLimited={true}
            error={!!searchDatesError?.message}
          />
        </Box>
        <Box pt={[4, 4, 0]} width={['100%', '100%', '25%']} order={[4, 3]} data-testid="occupant-picker">
          <OccupantPicker
            occupants={selectedOccupants}
            labelOptions={labelOptions}
            updateQuery={onUpdateQuery}
            viewThreshold={0}
            verboseInMobile
          />
        </Box>
        <PayWithWrapper flexDirection={['column-reverse', 'column-reverse', 'row']} order={6} width={['100%', '45%']}>
          <Flex alignItems="flex-start" flexDirection={'column'} width={['100%', '100%', '25%']}>
            <PayWithButtonGroup
              name="payWith"
              payWith={payWith || 'cash'}
              minPrice={0}
              maxPrice={0}
              updateQuery={onUpdateQuery}
              isHotelTab={true}
            />
            <Text color="greys.steel" pt={2} display="block" data-testid="minimum-points" alignSelf="flex-end" mr={[1, 1, 6]}>
              Use min. 5,000 points
            </Text>
          </Flex>
          <PayWithToggleMessage
            pointerDirection={isMobileOrTablet ? 'bottom' : 'left'}
            pointerPosition={isMobileOrTablet ? '90%' : '50%'}
            mb={[4, 4, 7]}
          />
        </PayWithWrapper>
        <SubmitButtonBox payWithToggleMessage={payWithToggleMessage}>
          <SubmitButton
            as={AppLink}
            to={searchUrl}
            variant="primary"
            onClick={onSubmitQuery}
            aria-label="Search hotels"
            width={'100%'}
            data-testid="search-hotels-cta"
          >
            Search hotels
          </SubmitButton>
        </SubmitButtonBox>
      </Flex>
    </Fragment>
  );
};

export default HotelsTab;
