const addMdRow = (mdArray) => {
  const mdTable = [...mdArray];

  // create a corresponding mdRow | ---- | for each cell
  const mdRow = mdTable.map(() => ' ---- ').join('|');
  // force add mdRow in index 1 (always the 2nd row)
  mdTable.splice(1, 0, mdRow);

  return mdTable;
};

const markdownTable = (table) => {
  const arrayToMarkdown = table.map((row) => row.join('|'));
  return addMdRow(arrayToMarkdown).join('\n');
};

const tableSerializer = (props) => {
  if (!props?.node?.rows?.length) return;

  const rowsToArray = props.node.rows.map((row) => row.cells);
  return markdownTable(rowsToArray);
};

export default tableSerializer;
