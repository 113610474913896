import toMarkdown from '@sanity/block-content-to-markdown';
import { SANITY_DATASET_NAME, SANITY_PROJECT_ID } from 'config';
import { blockImageSerializer, tableSerializer } from './blockMarkdownSerializers';

const markdownOpts = {
  dataset: SANITY_DATASET_NAME,
  projectId: SANITY_PROJECT_ID,
  serializers: {
    types: {
      image: blockImageSerializer,
      table: tableSerializer,
    },
  },
};

const getMarkdownForBlockContent = (content) => toMarkdown(content, markdownOpts);

export default getMarkdownForBlockContent;
