import __ from 'lodash/fp';

const getGroupedTerms = (termsAndConditions) =>
  __.flow(
    __.flatten,
    __.reject((entry) => __.isEmpty(entry) || __.isEmpty(entry.disclaimers)),
    __.groupBy('title'),
    __.map((entries) => ({
      ...entries[0],
      disclaimers: __.flow(__.map('disclaimers'), __.flatten)(entries),
    })),
  )(termsAndConditions);

export default getGroupedTerms;
