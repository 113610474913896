import getImage from '../getImage';

/**
 * Derived from https://github.com/sanity-io/block-content-to-markdown/blob/master/src/serializers.js
 * An enhanced version of the image serializer where we use our custom "caption" field for alt text
 * and to grab the field as a sibling.
 */
const blockImageSerializer = (props) => {
  if (!props?.node) return;
  const { asset, caption } = props.node;
  const url = getImage(asset);

  return `![${caption || ''}](${url.medium})`;
};

export default blockImageSerializer;
