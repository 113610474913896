import React, { useEffect } from 'react';
import { useDataLayer } from 'hooks/useDataLayer';
import Modal from 'components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { getProperty, getRequestCallbackModalOpen } from 'store/property/propertySelectors';
import { createCallbackRequest } from 'store/enquiry/enquiryActions';
import useRecaptcha from 'components/ContactUsPage/EnquiryPage/EnquiryForm/useRecaptcha';
import { getCallbackRequest } from 'store/enquiry/enquirySelectors';
import { EMAIL_ENQUIRY_STATUS } from 'lib/enums/contactUs';
import { RequestCallbackForm } from '@qga/components';
import { getMemberId, getFirstName, getLastName, getPhoneNumber } from 'store/user/userSelectors';
import PropTypes from 'prop-types';
import { fixBodyScroll, unfixBodyScroll } from 'lib/browser';
import { setRequestCallbackModalOpen } from 'store/property/propertyActions';

const RequestCallbackModal = ({ interactionType }) => {
  const dispatch = useDispatch();
  const property = useSelector(getProperty);
  const { getToken, RecaptchaBranding } = useRecaptcha();
  const { emitInteractionEvent } = useDataLayer();
  const firstName = useSelector(getFirstName);
  const lastName = useSelector(getLastName);
  const fullName = (firstName ? `${firstName} ` : '') + (lastName ? lastName : '');
  const phoneNumber = useSelector(getPhoneNumber);
  const qffNumber = useSelector(getMemberId);
  const callbackRequest = useSelector(getCallbackRequest);
  const isSubmitting = callbackRequest?.status === EMAIL_ENQUIRY_STATUS.SUBMITTING;
  const isComplete = callbackRequest?.status === EMAIL_ENQUIRY_STATUS.COMPLETE;

  const isOpen = useSelector(getRequestCallbackModalOpen);

  const closeModal = () => {
    dispatch(setRequestCallbackModalOpen(false));
  };

  useEffect(() => {
    if (isOpen) {
      fixBodyScroll();
    } else {
      unfixBodyScroll();
    }
  }, [isOpen]);

  const onSubmit = async (formData) => {
    const recaptchaToken = await getToken();

    const callbackArgs = {
      ...formData,
      recaptchaToken,
    };

    if (property) {
      callbackArgs.packageType = property.name;
    }
    dispatch(createCallbackRequest(callbackArgs));
    emitInteractionEvent({ type: `Request  callback for ${interactionType}`, value: 'Request submitted' });
  };

  return (
    <>
      <Modal isOpen={isOpen} shouldCloseOnOverlayClick onRequestClose={closeModal} title="Request callback">
        <>
          <RequestCallbackForm
            onSubmit={onSubmit}
            onClose={closeModal}
            isSubmitting={isSubmitting}
            isComplete={isComplete}
            prefilledDetails={{
              fullName,
              phoneNumber,
              qffNumber,
            }}
          />
          <RecaptchaBranding color="greys.alto" fontSize="xs" />
        </>
      </Modal>
    </>
  );
};

RequestCallbackModal.propTypes = {
  interactionType: PropTypes.string,
};

export default RequestCallbackModal;
